import React, {useState, useEffect} from "react"
import exploreImage from "../../images/explore.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {navigate} from "gatsby"
import Loader from '../../components/WidgetComponents/Loader'

// const NotFoundPage = () => (<Layout>
//   <SEO title="Missing Page"/>
//   <Grid container="container" direction="column" justify="center" alignItems="center" style={{
//       height: typeof window !== `undefined`
//         ? window.innerHeight
//         : ''
//     }}>
//     <Fade in="in">
//       <img id='mockup' alt='Not Found' src={notFoundImage} style={{
//           width: 'calc(150px + 20vw)'
//         }}/>
//     </Fade>
//   </Grid>
// </Layout>)

if (typeof window !== `undefined`) {
  WebFont.load({
    google: {
      families: ['Work Sans:lighter,normal,bold,bolder']
    }
  });
}

function NotFoundPage(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [innerHeight, setInnerHeight] = useState(null)

  useEffect(() => {
    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        setInnerHeight(window.innerHeight)
        setIsLoaded(true)
      }
    }
  }, [isLoaded]);

  const page = (<Fade in="in">
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        margin: '0px',
        height: innerHeight,
        // width: '100vw',
        padding: '0px 30px'
      }}>
      <SEO title="Explore"/>
      <Fade in="in">
        <img id='mockup' alt='Not Found' src={exploreImage} style={{
            width: 'calc(200px + 10vw)'
          }}/>
      </Fade>
      <Typography style={{
          fontFamily: 'Work Sans',
          fontWeight: 'bold',
          fontSize: '25px',
          padding: '20px',
          textAlign: 'center',
          lineHeight: 1.2
        }}>Explore with Us</Typography>
      <Typography style={{
          fontFamily: 'Work Sans',
          fontWeight: 'normal',
          fontSize: '20px',
          paddingBottom: '20px',
          textAlign: 'center',
          lineHeight: 1.3,
          maxWidth: '820px'
        }}>{"Help us choose where to go from here with new features and improvements."}</Typography>
      <Grid container="container" direction="row" wrap="wrap-reverse" justify="center" alignItems="center" style={{
          backgroundColor: '',
          padding: '10px'
        }}>
        <Button color='secondary' variant='contained' size='large' onClick={() => navigate('/onboard/features')}>Continue</Button>
      </Grid>
    </Grid>
  </Fade>)

  return (<Layout>
    {
      isLoaded
        ? page
        : <Loader/>
    }</Layout>)
}

export default NotFoundPage
